
import { defineComponent} from "vue";

import AuthLayout from '@/components/page-layouts/Auth.vue';

export default defineComponent({
  name: "app",
  components: {
    AuthLayout
  },
});

